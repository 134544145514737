import React, { useEffect, useState } from 'react';
import { Course, CourseLocation } from 'src/types/course';
import { DegreeAuditTerm, PathwayStatusValues } from 'src/types/degree-audit-term';
import { ElectivePlaceHolder } from 'src/types/elective-place-holder';
import { ProgressStatus } from 'src/types/progress-status';
import { LetterGradeCode } from 'src/types/letter-grade-code';
import { isValidDate } from 'src/utils/date-utils';
import { Alert, Box, CircularProgress, Link, Snackbar } from '@mui/material';
import {
  ExpansionDetails,
  ExpansionHeader,
  ExpansionItem,
  ExpansionPanel,
  ExpansionSummary,
} from '@pennfoster/pfc-design-system/molecules/expansion-panel';
import ElectivePlaceholder from '../../elective-placeholder';
import ProgressChip from '../../progress-chip';
import ExpansionRows from '../expansion-rows';
import Typography from '@pennfoster/pfc-design-system/atoms/typography';
import { InfoOutlined } from '@mui/icons-material';
import useCourseUrls from '../../../hooks/use-course-url';
import { shortenToEllipsis } from 'src/utils/string-utils';
import { DegreeCode } from 'src/types/degree-code';
import { Enrollment } from 'src/types/enrollment';

const maxCourseNameLength = 32;

interface ExpansionPanelWithItemsProps {
  term: DegreeAuditTerm;
  showLSAPlaceHolders: boolean;
  termId?: number | null;
  isCurrentTerm: boolean;
  learningEnabled: boolean | null;
  enrollment: Enrollment;
  setIsLoadingUrlCourses: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export default function ExpansionPanelWithItems({
  term: degreeAuditTerm,
  showLSAPlaceHolders,
  enrollment,
  termId,
  learningEnabled,
  setIsLoadingUrlCourses,
}: ExpansionPanelWithItemsProps) {
  const { courses, electives, electivePlaceHolders, pathwayStatus } = degreeAuditTerm;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useCourseUrls.enrollmentId = enrollment.enrollmentId!;
  const { handleCourseRedirect, queryStatus, currentCourseId } = useCourseUrls;
  let { error: courseUrlError } = useCourseUrls;

  useEffect(() => {
    if (queryStatus === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [queryStatus]);

  const alertMessage = (status: ProgressStatus) => {
    if (status && status == ProgressStatus.Failed) {
      return 'Your grade average for this course is below the requirement. Retake any failed assessments to raise your grade average and complete the course.';
    }
    if (status && status == ProgressStatus.Locked) {
      return 'This course is currently unavailable. Complete the preceding course(s) to unlock this one.';
    }
    return '';
  };

  const alertMessageSeverity = (status: ProgressStatus) => {
    if (status && status == ProgressStatus.Failed) {
      return 'error';
    }
    return 'info';
  };

  const renderCourseItems = (course: Course) => (
    <ExpansionItem key={`program-courses-${course.sisStudentCourseId}`}>
      <ExpansionSummary
        dataColumnWidth={2}
        mainColumnWidth={4}
        details={[
          course.status ? (
            <ProgressChip
              key={`program-courses-chip-${course.sisStudentCourseId}`}
              status={course.status}
              isStatusForLesson={false}
            />
          ) : (
            '-'
          ),
          course.courseLocation == CourseLocation.Engen || course.letterGrade == LetterGradeCode.Credit
            ? course.letterGrade
            : course.numericGrade != null
            ? Number.isInteger(course.numericGrade)
              ? course.numericGrade.toString()
              : course.numericGrade.toFixed(2)
            : '-',
          course.completionDate && isValidDate(course.completionDate)
            ? new Date(course.completionDate).toLocaleDateString()
            : '-',
          enrollment.degree.code == DegreeCode.CareerDiploma || enrollment.degree.code == DegreeCode.CareerCertificate
            ? ''
            : course.creditAmount ?? '-',
        ]}
      >
        {course.status === ProgressStatus.Locked || !learningEnabled || course.letterGrade == LetterGradeCode.Credit ? (
          course.name
        ) : (
          <>
            <Link onClick={() => handleCourseRedirect(course.sisStudentCourseId, setIsLoadingUrlCourses)}>
              {shortenToEllipsis(course.name, maxCourseNameLength)}
            </Link>
            {isLoading && course.sisStudentCourseId === currentCourseId ? (
              <CircularProgress sx={{ ml: 1 }} size={'16px'} />
            ) : null}
          </>
        )}
      </ExpansionSummary>
      <ExpansionDetails>
        {(course.status == ProgressStatus.Failed || course.status == ProgressStatus.Locked) && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Alert
              icon={<InfoOutlined />}
              sx={{
                maxWidth: '656px',
              }}
              severity={alertMessageSeverity(course.status)}
            >
              {alertMessage(course.status)}
            </Alert>
          </Box>
        )}

        {course.lessons && course.lessons.length != 0 && (
          <ExpansionHeader sx={{ width: '100%' }}>
            <Typography fontSize="12px" fontWeight="bold" color="#104c84">
              Lesson Title
            </Typography>
          </ExpansionHeader>
        )}

        {course.lessons && <ExpansionRows key={course.sisStudentCourseId} items={course.lessons} />}
      </ExpansionDetails>
    </ExpansionItem>
  );

  const renderElectiveItems = (elective: Course) => (
    <ExpansionItem key={`program-electives-${elective.sisStudentCourseId}`}>
      <ExpansionSummary
        details={[
          elective.status ? (
            <ProgressChip
              key={`program-electives-chip-${elective.sisStudentCourseId}`}
              status={elective.status}
              isStatusForLesson={false}
            />
          ) : (
            '-'
          ),
          elective.courseLocation == CourseLocation.Engen || elective.letterGrade == LetterGradeCode.Credit
            ? elective.letterGrade
            : elective.numericGrade != null
            ? Number.isInteger(elective.numericGrade)
              ? elective.numericGrade.toString()
              : elective.numericGrade.toFixed(2)
            : '-',
          elective.completionDate ? new Date(elective.completionDate).toLocaleDateString() : '-',
          enrollment.degree.code == DegreeCode.CareerDiploma || enrollment.degree.code == DegreeCode.CareerCertificate
            ? ''
            : elective.creditAmount ?? '-',
        ]}
      >
        {elective.status === ProgressStatus.Locked ||
        !learningEnabled ||
        elective.letterGrade == LetterGradeCode.Credit ? (
          elective.name
        ) : (
          <>
            <Link onClick={() => handleCourseRedirect(elective.sisStudentCourseId, setIsLoadingUrlCourses)}>
              {elective.name}
            </Link>
            {isLoading && elective.sisStudentCourseId === currentCourseId ? (
              <CircularProgress sx={{ ml: 1 }} size={'16px'} />
            ) : null}
          </>
        )}
      </ExpansionSummary>
      <ExpansionDetails>
        {(elective.status == ProgressStatus.Failed || elective.status == ProgressStatus.Locked) && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Alert
              icon={<InfoOutlined />}
              sx={{
                maxWidth: '656px',
              }}
              severity={alertMessageSeverity(elective.status)}
            >
              {alertMessage(elective.status)}
            </Alert>
          </Box>
        )}

        {elective.lessons && elective.lessons.length != 0 && (
          <ExpansionHeader>
            <Typography fontSize="12px" fontWeight="bold" color="#104c84">
              Lesson Title
            </Typography>
          </ExpansionHeader>
        )}

        {elective.lessons && <ExpansionRows key={elective.sisStudentCourseId} items={elective.lessons} />}
      </ExpansionDetails>
    </ExpansionItem>
  );
  const renderElectivePlaceholders = (placeholder: ElectivePlaceHolder, i: number) => (
    <ExpansionItem key={`program-elective-placeholders-${i}`}>
      <ElectivePlaceholder
        showLSAPlaceHolders={showLSAPlaceHolders}
        index={i}
        electivePoolName={placeholder.name}
        electivePoolId={placeholder.sisElectivePoolId}
        termId={termId}
        pathwayStatus={pathwayStatus}
      />
    </ExpansionItem>
  );
  return (
    <Box sx={{ overflowX: 'auto' }}>
      <ExpansionPanel dataColumnWidth={2} mainColumnWidth={4} singleExpand>
        <ExpansionItem header>
          <ExpansionSummary data-testid="item-1" details={['Status', 'Grade', 'Date', 'Credits']}>
            Course Title
          </ExpansionSummary>
        </ExpansionItem>
        {courses.map(renderCourseItems)}
        {electives.map(renderElectiveItems)}
        {electivePlaceHolders.map(renderElectivePlaceholders)}
        <Snackbar
          open={courseUrlError}
          onClose={() => {
            courseUrlError = false;
          }}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={'error'}>There was an error redirecting you to your course. Contact support.</Alert>
        </Snackbar>
      </ExpansionPanel>
    </Box>
  );
}
