export interface Payment {
  id: number;
  type: string;
  amount: number;
  paymentMethodId: number;
  dateCreated: string;
  createdDate: Date;
}

export interface Transactions {
  id: number;
  charge: string;
  date: string;
  payment: string;
  type: string;
  plan: string;
}

export interface TransactionFields {
  id: number;
  charge: string;
  date: string;
  type: string;
  plan: string;
  payment: string;
}

export interface CreatePaymentDto {
  amount: number;
  paymentMethodId: number;
}

export interface PaymentAddress {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

export interface UpdatePaymentMethodDto {
  expiration?: string | null;
  cvv?: string | null;
  isDefault?: boolean | null;
  isBackup?: boolean | null;
  address?: PaymentAddress | null;
}

export interface PaymentMethod {
  type?: string;
  brand?: string;
  lastFour?: string;
  expiration?: string;
  nameOnCard?: string;
  id?: number;
  isDefault?: boolean;
  bankName?: string;
  routingNumber?: string;
  isBackup?: boolean;
  expired?: boolean;
}

export interface IPaymentMethods {
  nameOnCard: string;
  cardNumber: string;
  expiration: string;
  zipCode: string;
  isDefault: boolean;
  isBackup: boolean;
}

export interface PaymentPlans {
  paymentPlanSisId: number;
  paymentPlanId: string;
  programName: string;
  programDisplayName: string;
  termId: number;
  type: string;
  remainingBalance: number;
  status: string;
  nextPaymentAmount: number;
  maximumPaymentAmount: number;
}

export interface ScheduledPayment {
  amountDue: number;
  dueDate: Date;
  sisPaymentPlanId: number;
  sisPaymentScheduledId: number;
  programDisplayName: string;
  programName: string;
  status: string;
  sisTermId: number;
  type: string;
}

export interface ScheduledPaymentData {
  total: number;
  paymentsScheduled: ScheduledPayment[];
}

export enum PaymentBrand {
  amex = 'amex',
  visa = 'visa',
  mastercard = 'mastercard',
  discover = 'discover',
}

export type PaymentBrandName = 'American Express' | 'Visa' | 'Mastercard' | 'Discover';

export const PaymentBrandLabels: Record<PaymentBrand, PaymentBrandName> = {
  [PaymentBrand.amex]: 'American Express',
  [PaymentBrand.visa]: 'Visa',
  [PaymentBrand.mastercard]: 'Mastercard',
  [PaymentBrand.discover]: 'Discover',
};

export interface NotificationPaymentErrorCardProps {
  errorTitle: string;
  errorMessage: string;
  makePayment: boolean;
  setNotificationProps: (notificationProps: NotificationPaymentErrorCardProps | null) => void;
}

// CalculateSalesTaxRequest
export interface CalculateSalesTaxRequest {
  payInFull: boolean;
  chargedAmount: number;
  sisPaymentPlanId?: number;
  salesTaxItems: SalesTaxItem[];
  sisPaymentMethodId: number;
}

// SalesTaxItem
export interface SalesTaxItem {
  type: 'Fee' | 'Tuition';
  sisPaymentScheduledId?: number;
  amount: number;
  termId: number;
}

// ChargeStudentDTO
export interface ChargeStudent {
  baseAmount : number;
  totalAmount: number;
  totalTaxAmount: number;
  sisPaymentPlanId: number;
  chargeStudentItems: ChargeStudentItem[];
  sisPaymentMethodId: number;
  paymentType: string;
  payInFull?: boolean;
}

// ChargeStudentItemDTO
export interface ChargeStudentItem {
  type: 'Fee' | 'Tuition';
  amount: number;
  taxAmount: number;
  sisPaymentScheduledId?: number;
  termId: number;
}

export interface NextPaymentData {
  amountDue: number;
  dueDate: Date;
  paymentPlanType: string;
  programName: string;
  paymentScheduleType?: string;
}

export enum PaymentMethodSelection {
  default = 'Default',
  backup = 'Backup',
}

export interface SalesTaxesTransactions {
  isSuccessful: boolean;
  documentStatus: string;
  orderId: string;
  taxAmount: number;
  termId: number;
}

export interface PaymentTaxesResponse {
  totalAmount: number;
  salesTaxTransactions: SalesTaxesTransactions[];
}
