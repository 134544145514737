import { Typography } from '@mui/material';
import { useContext } from 'react';
import ChatbotContext from 'src/context/chatbot';
import { cms } from 'src/helpers/language';
import { useNavigate } from 'react-router-dom';
import { AUTH_HELP } from 'src/routes/routemap';

export interface FooterProps {
  helpCenterLink?: string;
  supportCaseLink?: string;
}

export default function HelpFooter({ supportCaseLink }: FooterProps) {
  const { chatbot } = useContext(ChatbotContext);
  const content = cms.content.general.footer;

  const callAdaToggle = (event: React.MouseEvent<HTMLAnchorElement>) => {
    chatbot?.toggle();
    event.preventDefault();
  };

  const navigate = useNavigate();

  return (
    <footer>
      <Typography>
        {content.assistance_question}
        {content.assistance_option_1}
        <a
          href=""
          onClick={() => {
            navigate(AUTH_HELP);
          }}
          target="_blank"
          rel="noreferrer"
        >
          {content.assistance_option_1_link_label}
        </a>
        {content.assistance_option_2}
        <a href="#" onClick={callAdaToggle}>
          {content.assistance_option_2_link_label}
        </a>
        {/* {content.assistance_option_3}
        <a href={supportCaseLink} target="_blank" rel="noreferrer">
          {content.assistance_option_3_link_label}
        </a> */}
        {content.assistance_end}
      </Typography>
    </footer>
  );
}
